import React, { useState, useEffect } from 'react';
import './Leads.css'; // Import CSS file for styling
import axios from 'axios'
const ClientCompany = () => {
  
  const [sheetData, setSheetData] = useState([]);
  
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get('https://apikey.intervuer.com/talent/glossary');
        setSheetData(response.data)
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    }
  
    fetchData();
    const interval = setInterval(fetchData, 5000);
  
    return () => clearInterval(interval);
  }, []);
  
  
  return (
    <div className="scrollable-table-container">
    <div className="sticky-header">
      <table>
        <thead>
          <tr>
            <th style={{ minWidth: '30px', position: 'sticky', left: 0, zIndex: 1, backgroundColor:'#f8f8f8' }}>Sr. No.</th>
            <th style={{ minWidth: '20px', position: 'sticky', left: '30px', zIndex: 1, backgroundColor:'#f8f8f8', }}>Domain</th>
            <th style={{ minWidth: '30px', position: 'sticky', left: '80px', zIndex: 1, backgroundColor:'#f8f8f8' }}>Term</th>
            <th style={{ minWidth: '194px',backgroundColor:'#f8f8f8'}}>Definition</th>
           
          </tr>
        </thead>
      </table>
    </div>
    <table>
      <tbody>
        {sheetData.slice(1).map((row, rowIndex) => (
          <tr key={rowIndex}>
            {/* Render fixed columns */}
            <td style={{ minWidth: '130px', position: 'sticky', left: 0, zIndex: 1, backgroundColor:'#f8f8f8' }}>
              {row[0]} {/* Assuming row[0] corresponds to the first fixed column */}
            </td>
            <td style={{ minWidth: '130px', position: 'sticky', left: '130px', zIndex: 1, backgroundColor:'#f8f8f8' }}>
              {row[1]} {/* Assuming row[-2] corresponds to the second fixed column */}
            </td>
            <td style={{ minWidth: '130px', position: 'sticky', left: '260px', zIndex: 1, backgroundColor:'#f8f8f8' }}>
              {row[2]} {/* Assuming row[4] corresponds to the third fixed column */}
            </td>
            
            {/* Render remaining columns */}
            {[row[3]].map((cell, cellIndex) => (
              <td key={cellIndex} style={{ minWidth: '130px',height:'40px' }}>
                {cell}
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  </div>
  
  );
};

export default ClientCompany;

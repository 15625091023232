import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './D&P.css'

const DocumentsandPolicies = () => {
  const [files, setFiles] = useState([]);

  useEffect(() => {
    fetchFiles();
    const interval = setInterval(fetchFiles, 1000);
  
    return () => clearInterval(interval);
  }, []);

  const fetchFiles = async () => {
    try {
      const response = await axios.get('/files');
      setFiles(response.data);
    } catch (error) {
      console.error('Error fetching files:', error);
    }
  };

  const handleDownload = async (fileName) => {
    try {
      const response = await axios.get(`https://apikey.intervuer.com/files/${fileName}`, {
        responseType: 'blob', // Important for downloading files
      });
      
      // Create a URL for the file blob
      const url = window.URL.createObjectURL(new Blob([response.data]));

      // Create a temporary link element
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', fileName);

      document.body.appendChild(link);
      link.click();

      link.parentNode.removeChild(link);
    } catch (error) {
      console.error('Error downloading file:', error);
    }
  };

  return (
    <div className="documents-container">
    <h2 className="documents-heading">User File List</h2>
    <ul className="documents-list">
      {files.map((file, index) => (
        <li className="document-item" key={index}>
          {file}
          <button className="download-button" onClick={() => handleDownload(file)}>Download</button>
        </li>
      ))}
    </ul>
  </div>
  
  );
};

export default DocumentsandPolicies;

import React, { useState, useEffect } from 'react';
import './Leads.css'; // Import CSS file for styling
import axios from 'axios'
const ClientCompany = () => {
  
  const [sheetData, setSheetData] = useState([]);
  
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get('https://apikey.intervuer.com/talent/clients');
        setSheetData(response.data)
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    }
  
    fetchData();
    const interval = setInterval(fetchData, 5000);
  
    return () => clearInterval(interval);
  }, []);
  
  
  return (
    <div className="scrollable-table-container">
    <div className="sticky-header">
      <table>
        <thead>
          <tr>
            <th style={{ minWidth: '130px', position: 'sticky', left: 0, zIndex: 1, backgroundColor:'#f8f8f8' }}>CLient I.D</th>
            <th style={{ minWidth: '130px', position: 'sticky', left: '130px', zIndex: 1, backgroundColor:'#f8f8f8' }}>Status</th>
            <th style={{ minWidth: '130px', position: 'sticky', left: '260px', zIndex: 1, backgroundColor:'#f8f8f8' }}>Head Office (City)</th>
            <th style={{ minWidth: '130px', position: 'sticky', left: '390px', zIndex: 1, backgroundColor:'#f8f8f8' }}>Company registered/incorporated as</th>
            <th style={{ minWidth: '130px', position: 'sticky', left: '520px', zIndex: 1, backgroundColor:'#f8f8f8' }}>Looking for resources for the position of</th>
            <th style={{ minWidth: '130px',backgroundColor:'#f8f8f8'}}>Looking for resources for the position of</th>
            <th style={{ minWidth: '130px',backgroundColor:'#f8f8f8'}}>Department</th>
            <th style={{ minWidth: '130px',backgroundColor:'#f8f8f8'}}>Required Skills </th>
            <th style={{ minWidth: '130px',backgroundColor:'#f8f8f8'}}>Industry</th>
            <th style={{ minWidth: '130px',backgroundColor:'#f8f8f8'}}>Total Number of resources needed for above-mentioned position</th>
            <th style={{ minWidth: '130px',backgroundColor:'#f8f8f8'}}>Number of Female Resources Needed</th>
            <th style={{ minWidth: '130px',backgroundColor:'#f8f8f8'}}>Number of Male Resources Needed</th>
            <th style={{ minWidth: '130px',backgroundColor:'#f8f8f8'}}>Salary Bracket for the said role</th>
            <th style={{ minWidth: '130px',backgroundColor:'#f8f8f8'}}>Requirement for Experience</th>
            <th style={{ minWidth: '130px',backgroundColor:'#f8f8f8'}}>Do your company provide leads to the sales resources? </th>
            <th style={{ minWidth: '130px',backgroundColor:'#f8f8f8'}}>(For Sales Dept. Only) What Percent Commission your company offers per sale?</th>
            <th style={{ minWidth: '130px',backgroundColor:'#f8f8f8'}}>(For Sales Dept. Only) Type of Inventory Your Company Sells</th>
            <th style={{ minWidth: '130px',backgroundColor:'#f8f8f8'}}>Requirement for Experience in Sales</th>
            <th style={{ minWidth: '130px',backgroundColor:'#f8f8f8'}}>Minimum Qualification Requirements</th>
            <th style={{ minWidth: '130px',backgroundColor:'#f8f8f8'}}>Language Proficiency Required</th>
            <th style={{ minWidth: '130px',backgroundColor:'#f8f8f8'}}>Office Location</th>
            <th style={{ minWidth: '130px',backgroundColor:'#f8f8f8'}}>Do your company offer Pick & Drop Services for the employees? </th>
            <th style={{ minWidth: '130px',backgroundColor:'#f8f8f8'}}>Do your company provide laptop/system for official use of the sales resources?</th>
            <th style={{ minWidth: '130px',backgroundColor:'#f8f8f8'}}>Do your company provide mobile phones for official use of the sales resources ?</th>
            <th style={{ minWidth: '130px',backgroundColor:'#f8f8f8'}}>Do your company offer Insurance Services for the employees? </th>
          </tr>
        </thead>
      </table>
    </div>
    <table>
      <tbody>
        {sheetData.slice(1).map((row, rowIndex) => (
          <tr key={rowIndex}>
            {/* Render fixed columns */}
            <td style={{ minWidth: '130px', position: 'sticky', left: 0, zIndex: 1, backgroundColor:'#f8f8f8' }}>
              {row[3]} {/* Assuming row[0] corresponds to the first fixed column */}
            </td>
            <td style={{ minWidth: '130px', position: 'sticky', left: '130px', zIndex: 1, backgroundColor:'#f8f8f8' }}>
              {row[4]} {/* Assuming row[-2] corresponds to the second fixed column */}
            </td>
            <td style={{ minWidth: '130px', position: 'sticky', left: '260px', zIndex: 1, backgroundColor:'#f8f8f8' }}>
              {row[28]} {/* Assuming row[4] corresponds to the third fixed column */}
            </td>
            <td style={{ minWidth: '130px', position: 'sticky', left: '390px', zIndex: 1, backgroundColor:'#f8f8f8' }}>
              {row[6]} {/* Assuming row[5] corresponds to the fourth fixed column */}
            </td>
            <td style={{ minWidth: '130px', position: 'sticky', left: '520px', zIndex: 1, backgroundColor:'#f8f8f8' }}>
              {row[29]} {/* Assuming row[5] corresponds to the fourth fixed column */}
            </td>
            {/* Render remaining columns */}
            {[row[29], row[30], row[32], row[33], row[8], row[17], row[18], row[9], row[31],row[15],row[16],row[22],['not found'],row[23],row[24],row[10],row[11],row[12],row[13],row[14]].map((cell, cellIndex) => (
              <td key={cellIndex} style={{ minWidth: '130px' }}>
                {cell}
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  </div>
  
  );
};

export default ClientCompany;

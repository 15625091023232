import React, { useState, useEffect } from 'react';
import './Leads.css'; // Import CSS file for styling
import axios from 'axios'
const TodoList = () => {
  
  const [sheetData, setSheetData] = useState([]);
  
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get('https://apikey.intervuer.com/talent/data-recruite');
  
        const today = new Date().toISOString().split('T')[0];
     
  
        const filteredData = response.data.filter(row => {
          const rowDate45 = row[45]; // Assuming the date is in the 45th column of each row
          const rowDate49 = row[49]; // Assuming the date is in the 49th column of each row
          
      
  
          const rowDateFormatted45 = formatDate(rowDate45);
          const rowDateFormatted49 = formatDate(rowDate49);
  
  
          return rowDateFormatted45 === today || rowDateFormatted49 === today;
        });
        setSheetData(filteredData);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
  
    fetchData();
    const interval = setInterval(fetchData, 5000);
  
    return () => clearInterval(interval);
  }, []);
  
  function formatDate(date) {
    if (!date) return null; // Handle cases where the date value is null or undefined
  
    const dateParts = date.split(' '); // Split date-time string by space
  
    if (dateParts.length > 0) {
      const dateString = dateParts[0]; // Extract the date part
      const dateObject = new Date(dateString);
  
      if (!isNaN(dateObject.getTime())) { // Check if the dateObject is valid
        return dateObject.toISOString().split('T')[0];
      }
    }
  
    return null; // Return null for invalid or unrecognized date formats
  }
  
  
  return (
    <div className="scrollable-table-container">
    <div className="sticky-header">
      <table>
        <thead>
          <tr>
            <th style={{ minWidth: '130px', position: 'sticky', left: 0, zIndex: 1, backgroundColor:'#f8f8f8' }}>Sr. No.</th>
            <th style={{ minWidth: '130px', position: 'sticky', left: '130px', zIndex: 1, backgroundColor:'#f8f8f8' }}>User ID</th>
            <th style={{ minWidth: '130px', position: 'sticky', left: '260px', zIndex: 1, backgroundColor:'#f8f8f8' }}>Name</th>
            <th style={{ minWidth: '130px', position: 'sticky', left: '390px', zIndex: 1, backgroundColor:'#f8f8f8' }}>Contact</th>
            <th style={{ minWidth: '130px',backgroundColor:'#f8f8f8'}}>City</th>
            <th style={{ minWidth: '130px',backgroundColor:'#f8f8f8'}}>Status</th>
            <th style={{ minWidth: '130px',backgroundColor:'#f8f8f8'}}>Category</th>
            <th style={{ minWidth: '130px',backgroundColor:'#f8f8f8'}}>Lead <br />Gen. Date</th>
            <th style={{ minWidth: '130px',backgroundColor:'#f8f8f8'}}>Current Company</th>
            <th style={{ minWidth: '130px',backgroundColor:'#f8f8f8'}}>Sales Experience</th>
            <th style={{ minWidth: '130px',backgroundColor:'#f8f8f8'}}>Designation</th>
            <th style={{ minWidth: '130px',backgroundColor:'#f8f8f8'}}>MAPSS <br /> Score</th>
            <th style={{ minWidth: '130px',backgroundColor:'#f8f8f8'}}>Lead Assigned To</th>
            <th style={{ minWidth: '130px',backgroundColor:'#f8f8f8'}}>Tasks</th>
          </tr>
        </thead>
      </table>
    </div>
    <table>
      <tbody>
        {sheetData.map((row, rowIndex) => (
          <tr key={rowIndex}>
            {/* Render fixed columns */}
            <td style={{ minWidth: '130px', position: 'sticky', left: 0, zIndex: 1, backgroundColor:'#f8f8f8' }}>
              {row[0]} {/* Assuming row[0] corresponds to the first fixed column */}
            </td>
            <td style={{ minWidth: '130px', position: 'sticky', left: '130px', zIndex: 1, backgroundColor:'#f8f8f8' }}>
              {row[-2]} {/* Assuming row[-2] corresponds to the second fixed column */}
            </td>
            <td style={{ minWidth: '130px', position: 'sticky', left: '260px', zIndex: 1, backgroundColor:'#f8f8f8' }}>
              {row[4]} {/* Assuming row[4] corresponds to the third fixed column */}
            </td>
            <td style={{ minWidth: '130px', position: 'sticky', left: '390px', zIndex: 1, backgroundColor:'#f8f8f8' }}>
              {row[5]} {/* Assuming row[5] corresponds to the fourth fixed column */}
            </td>
            
            {/* Render remaining columns */}
            {[row[8], row[36], row[37], row[1], row[19], row[18], row[30], row[52], row[43]].map((cell, cellIndex) => (
              <td key={cellIndex} style={{ minWidth: '130px' }}>
                {cell}
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  </div>
  
  );
};

export default TodoList;

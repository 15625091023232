import React, { useState } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import UploadFiles from './Components/Admin/FilesUpload'
import Login from './Components/Login';
import AdminDashboard from './Components/Admin/AdminDashboard';
import TalentAqDashboard from './Components/TalentAq/TalentAqDashbaord';
import DashboardChart from './Components/Admin/DashBoardCharts';
import Leads from './Components/Admin/Leads'
import Users from './Components/Admin/ManageUsers/Users';
import  Tab  from './Components/TalentAq/Tab'
import Shortlisted from './Components/TalentAq/Shortlisted'
import Interviews from './Components/TalentAq/Interviews'
import Search  from  './Components/TalentAq/Search'
import Summary from './Components/TalentAq/Summary'

function App() {
  const [color,setcolor] =useState('#475be1')
  const getLogin =(color)=>{
     setcolor(color)
  }
  return (


    <Router>
      <Routes>
        <Route path="/" element={<Login  getLogin={getLogin}/>} />
 
        <Route path="/admin/*" element={<AdminDashboard color={color}/>}>
          <Route index element={<DashboardChart />} />
          <Route path="settings" element={<UploadFiles/>} />
          <Route path="users" element={<Users />} />
          <Route path="leads" element={< Leads color={color}/>} />
        </Route>

        <Route path="/talent/*" element={<TalentAqDashboard color={color}/>}>
          <Route index element={<Tab/>} />
          <Route path="summary" element={<Summary color={color}/>} />
          <Route path="search" element={<Search color={color}/>} />
          <Route path="shortlisted" element={<Shortlisted/>} />
          <Route path="interviews" element={<Interviews/>} />
          <Route path="notifications" element={<>Notifications</>} />
        </Route>
        
        {/* Other routes */}
        <Route path="/contact" element={<h1>Contact</h1>} />
      </Routes>
    </Router>
  );
}

export default App;

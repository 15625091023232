import React, { useState, useEffect } from 'react';
import '..//TalentAq/TabsCom/Leads.css'; // Import CSS file for styling
import axios from 'axios';

const Search = ({color}) => {
  const [sheetData, setSheetData] = useState([]);
  const [searchQuery, setSearchQuery] = useState('');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get('https://apikey.intervuer.com/talent/data-recruite');

        setSheetData(response.data);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
    const interval = setInterval(fetchData, 5000);

    return () => clearInterval(interval);
  }, []);

  const handleSearch = (e) => {
    setSearchQuery(e.target.value);
  };

  const handleStartDateChange = (e) => {
    setStartDate(e.target.value);
  };

  const handleEndDateChange = (e) => {
    setEndDate(e.target.value);
  };
  const filteredData = sheetData.slice(2).filter((row) => {
    const rowDate = new Date(row[44]); // Assuming the date column index
    const start = startDate ? new Date(startDate) : null;
    const end = endDate ? new Date(endDate) : null;
  
    if (start && end) {
      return rowDate >= start && rowDate <= end;
    } else if (start) {
      return rowDate >= start;
    } else if (end) {
      return rowDate <= end;
    } else {
      return true;
    }
  });
  

  return (
    <>
      <div className="search-container">
        <input
          type="text"
          placeholder="Search..."
          value={searchQuery}
          onChange={handleSearch}
          className="mb-4 mx-4"
        />
        <label htmlFor="searchdate">Search by Date : </label>
        <input
          id="searchdate"
          type="date"
          value={startDate}
          onChange={handleStartDateChange}
          className="mx-2"
        />
        <label htmlFor="searchdate">To</label>-
        <label htmlFor="fromdate">From</label>
        <input
          id='fromdate'
          type="date"
          value={endDate}
          onChange={handleEndDateChange}
          className="mx-2"
        />
      </div>
      <div className="scrollable-table-container">
   
   <div className="sticky-header">
   <table>
     <thead>
       <tr>
         <th style={{ minWidth: '130px', position: 'sticky', left: 0, zIndex: 1, backgroundColor:color }}>Sr. No.</th>
         <th style={{ minWidth: '130px', position: 'sticky', left: '130px', zIndex: 1, backgroundColor:'#f8f8f8' }}>User ID</th>
         <th style={{ minWidth: '130px', position: 'sticky', left: '260px', zIndex: 1, backgroundColor:'#f8f8f8' }}>Name</th>
         <th style={{ minWidth: '130px', position: 'sticky', left: '390px', zIndex: 1, backgroundColor:'#f8f8f8' }}>Contact</th>
         <th style={{ minWidth: '130px',backgroundColor:'#f8f8f8'}}>City</th>
         <th style={{ minWidth: '130px',backgroundColor:'#f8f8f8'}}>Status</th>
         <th style={{ minWidth: '130px',backgroundColor:'#f8f8f8'}}>Category</th>
         <th style={{ minWidth: '130px',backgroundColor:'#f8f8f8'}}>Lead <br />Gen. Date</th>
         <th style={{ minWidth: '130px',backgroundColor:'#f8f8f8'}}>Current Company</th>
         <th style={{ minWidth: '130px',backgroundColor:'#f8f8f8'}}>Sales Experience</th>
         <th style={{ minWidth: '130px',backgroundColor:'#f8f8f8'}}>Designation</th>
         <th style={{ minWidth: '130px',backgroundColor:'#f8f8f8'}}>MAPSS <br /> Score</th>
         <th style={{ minWidth: '130px',backgroundColor:'#f8f8f8'}}>Lead Assigned To</th>
         <th style={{ minWidth: '130px',backgroundColor:'#f8f8f8'}}>Tasks</th>
       </tr>
     </thead>
   </table>
 </div>
 <table>
   <tbody>
     {filteredData.map((row, rowIndex) => (
       <tr key={rowIndex}>
         {/* Render fixed columns */}
         <td style={{ minWidth: '130px', position: 'sticky', left: 0, zIndex: 1, backgroundColor:'#f8f8f8' }}>
           {row[0]} {/* Assuming row[0] corresponds to the first fixed column */}
         </td>
         <td style={{ minWidth: '130px', position: 'sticky', left: '130px', zIndex: 1, backgroundColor:'#f8f8f8' }}>
           {row[-2]} {/* Assuming row[-2] corresponds to the second fixed column */}
         </td>
         <td style={{ minWidth: '130px', position: 'sticky', left: '260px', zIndex: 1, backgroundColor:'#f8f8f8' }}>
           {row[4]} {/* Assuming row[4] corresponds to the third fixed column */}
         </td>
         <td style={{ minWidth: '130px', position: 'sticky', left: '390px', zIndex: 1, backgroundColor:'#f8f8f8' }}>
           {row[5]} {/* Assuming row[5] corresponds to the fourth fixed column */}
         </td>
         
         {/* Render remaining columns */}
         {[row[8], row[36], row[37], row[1], row[19], row[18], row[30], row[52], row[43]].map((cell, cellIndex) => (
           <td key={cellIndex} style={{ minWidth: '130px' }}>
             {cell}
           </td>
         ))}
         </tr>
       ))}
     </tbody>
   </table>
 </div></>
  );
};

export default Search;


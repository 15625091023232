import React, { useState } from 'react';
import { BrowserRouter as Routes, Route, BrowserRouter, Outlet } from 'react-router-dom';
import Sidebar from './SideBarT';
import './Talent.css';

function TalentAqDashboard({ color }) {
  const [dropdownVisible, setDropdownVisible] = useState(false);

  const toggleDropdown = () => {
    setDropdownVisible(!dropdownVisible);
  };

  const handleLogout = () => {
    // Implement logout functionality here
    console.log('Logout clicked');
  };

  return (
    <div className="D-container">
      <div className="D-sidebar" style={{ backgroundColor: color }}>
        <Sidebar color={color} />
      </div>
      <div className="D-content">
        <div className="profile">
          <div className="profile-image" onClick={toggleDropdown}>
            {/* Placeholder for circular image */}
            <img src="https://via.placeholder.com/150" alt="Profile" />
          </div>
          <div className="profile-details">
            {/* Display user details here, e.g., username */}
          </div>
        </div>
        {dropdownVisible && (
          <div className="dropdown-menu">
            <ul>
              <li onClick={handleLogout}>Logout</li>
              {/* Add more dropdown items as needed */}
            </ul>
          </div>
        )}
        <Outlet />
      </div>
    </div>
  );
}

export default TalentAqDashboard;

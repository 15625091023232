import React, { useState } from 'react';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import TodoList from './TabsCom/TodoList'
import './Talent.css';
import Leads from './TabsCom/Leads'
import ClientCompany from './TabsCom/ClientsCompany';
import Glossary from './TabsCom/Glossary'
import DocumentsandPolicies from './TabsCom/DocumentsandPolicies';

const TabsT = () => {

  const [selectedTab, setSelectedTab] = useState('leads');

  const handleTabSelect = (tab) => {
    setSelectedTab(tab);
  };

  return (
    <Tabs
      activeKey={selectedTab}
      onSelect={handleTabSelect}
      className="custom-tabs" // Apply custom class for styling
    >
      <Tab eventKey="leads" title="Leads" className="custom-tab">
             <Leads />
      </Tab>
      <Tab eventKey="todo" title="To-Do List" className="custom-tab">
       <TodoList/>
      </Tab>
      <Tab eventKey="clients" title="Clients" className="custom-tab">
           <ClientCompany/>
      </Tab>
      <Tab eventKey="glossary" title="Glossary" className="custom-tab">
        <Glossary/>
      </Tab>
      <Tab eventKey="documents" title="Documents & Policies" className="custom-tab">
           <DocumentsandPolicies />
      </Tab>
    </Tabs>
  );
};

export default TabsT;

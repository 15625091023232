import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import axios from 'axios';
import './Login.css';
import DropdownButton from 'react-bootstrap/DropdownButton';
import Dropdown from 'react-bootstrap/Dropdown';
import AdminDashboard from './Admin/AdminDashboard';

// imports needed for new design 
import BackgroundPic from '../Assets/BImg1.jpg'

const Login = ({ getLogin }) => {
  const [username, setusername] = useState('');
  const [password, setPassword] = useState('');
  const [emailError, setEmailError] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [selectedRole, setSelectedRole] = useState('Role');
  const [selectedColor, setSelectedColor] = useState('black'); 

  const navigate = useNavigate();

  const onButtonClick = async () => {
    try {
      const response = await axios.post('https://apikey.intervuer.com/user/login', {
        username,
        password,
        role: selectedRole,
      });
      if (response.data.role === "Admin") {
        getLogin(selectedColor)
        navigate('/admin')
       

      } else if (response.data.role === "Talent Acquisition Specialist") {
        navigate('/talent')
        getLogin(selectedColor)
      } else {
        navigate('/')
      }

    } catch (error) {
      alert(error)
      navigate('/');
    }
  };

  return (
    <div className="login-container">
        {/* Left Side */}
        <div className="box">
            <div className="background-image-div">
                <div className="overlay"></div>
                <img src={BackgroundPic}/>
                <div className="context">
                    <div className="logo">
                        <h1>Interveur</h1>
                        <p>As a premier interviewing platform, we connect talented individuals with dynamic companies across industries. Join us on a journey of discovery and advancement.</p>
                    </div>
                    <div className="icons-div">
                        {/* Add links to the respective profiles */}
                        <a href="#" className="icon" ><i className="uil uil-facebook-f"></i></a>
                        <a href="#" className="icon" ><i className="uil uil-instagram"></i></a>
                        <a href="#" className="icon" ><i className="uil uil-linkedin"></i></a>
                        <a href="#" className="icon" ><i className="uil uil-twitter"></i></a>
                    </div>
                </div>
            </div>
        </div>
        {/* Right Side Coding again */}
        <div className="box right">
            <div className="form">
                <h3>LOG IN</h3>
                <label className="errorLabel">{emailError}</label>
                <input 
                    type="text" 
                    value={username} 
                    className='input' 
                    placeholder='username'
                    onChange={(ev) => setusername(ev.target.value)}
                />
                <label className="errorLabel">{passwordError}</label>
                <input 
                    type="password" 
                    value={password}
                    className='input' 
                    placeholder='password'
                    onChange={(ev) => setPassword(ev.target.value)}
                />
                <div className="preferences">
                    <label htmlFor="role">Role
                        <select 
                            title={selectedRole}
                            name="role" 
                            id="role"
                            onSelect={(eventKey) => setSelectedRole(eventKey)}
                        >
                            <option value="Admin">Admin</option>
                            <option value="HR">Talent Acquisition Specialist</option>
                            <option value="Technical">Candidate Evaluation Expert</option>
                        </select>
                    </label>
                    <label htmlFor="colors">Color
                        <select 
                            name="colors" 
                            id="colors"
                            title={selectedColor}
                            onSelect={(eventKey) => setSelectedColor(eventKey)}
                            >
                            <option value="Crimson">Crimson</option>
                            <option value="Purple">Purple</option>
                            <option value="Blue">Blue</option>
                        </select>
                    </label>
                </div>
                <button className='login-button' onClick={onButtonClick}>Login</button>
                
            </div>
        </div>
    </div>
  )
}

export default Login;

// Arif's Code commented here in case of any problem 
// Or if previous design is needed
/* 
    <div className={'mainContainer'}>
      <div className={'titleContainer'}>
        <div>Login</div>
      </div>
      <br />
      <div className={'inputContainer'}>
        <input
          type='text'
          value={username}
          placeholder="Enter your username here"
          onChange={(ev) => setusername(ev.target.value)}
          className={'inputBox'}
        />
        <label className="errorLabel">{emailError}</label>
      </div>
      <br />
      <div className={'inputContainer'}>
        <input
          type='password'
          value={password}
          placeholder="Enter your password here"
          onChange={(ev) => setPassword(ev.target.value)}
          className={'inputBox'}
        />
        <label className="errorLabel">{passwordError}</label>
      </div>
      <div style={{ marginTop: '40px' }}>
        <DropdownButton
          id="dropdown-basic-button"
          title={selectedRole}
          variant='primary'
          onSelect={(eventKey) => setSelectedRole(eventKey)}
        >
          <Dropdown.Item eventKey="Admin">Admin</Dropdown.Item>
          <Dropdown.Item eventKey="Talent Acquisition Specialist">Talent Acquisition Specialist</Dropdown.Item>
          <Dropdown.Item eventKey="Candidate Evaluation Expert">Candidate Evaluation Expert</Dropdown.Item>
        </DropdownButton>
      </div>

      // {/* Dropdown for selecting color 
      <div style={{ marginTop: '40px' }}>
        <DropdownButton
          id="dropdown-color-button"
          title={selectedColor}
          variant='primary'
          onSelect={(eventKey) => setSelectedColor(eventKey)}
        >
          <Dropdown.Item eventKey="crimson">crimson</Dropdown.Item>
          <Dropdown.Item eventKey="#333">dark</Dropdown.Item>
          <Dropdown.Item eventKey="#475be1">Blue</Dropdown.Item>
          <Dropdown.Item eventKey="purple">Purple</Dropdown.Item>
        </DropdownButton>
      </div>

      <br />
      <div className={'inputContainer'}>
        <input className={'inputButton'} type="button" onClick={onButtonClick} value={'Login'} />
      </div>

    </div> */

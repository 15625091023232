import React, { useState, useEffect } from "react";
import { PieChart, Pie, Legend } from "recharts";
import axios from "axios";
import '..//Admin/DashBoardChart.css'

const DashBoardChart = () => {
  const [sheetData, setSheetData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get('https://apikey.intervuer.com/talent/data-recruite');
        setSheetData(response.data);
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };
    
    fetchData();
    const interval = setInterval(fetchData, 5000);
  
    return () => clearInterval(interval);
  }, []);

  // Function to filter data for the Pie chart
  const getPieChartData = () => {
    const categories = ['Call Connected', 'Call Not Connected', 'Call Not Connected - Multiple Times', 'Call Back', 'Not Recommended', 'Shortlisted', 'Not Interested', 'Follow Up Call', 'Freelance', 'DCA', 'Wrong Number', 'Company Interview Scheduled', 'Video Call Scheduled', 'Recruited']; // Adjust category names as needed
    const colors = ['#8884d8', '#83a6ed', '#8dd1e1', '#82ca9d', '#ffc658', '#ff7300', '#ff9a8d', '#a4de6c', '#d0ed57', '#0088fe', '#00c49f', '#ff0000', '#ffc0cb', '#800080']; // Adjust colors for each category
    const data = categories.map((category, index) => ({
      name: category,
      value: sheetData.filter(row => row[36] === category).length, // Assuming the interview status is in the 36th column
      fill: colors[index] // Assigning colors based on index
    }));
    return data;
  };

  return (
    <div>
      <h2>Pie Chart</h2>
      <PieChart width={400} height={300}>
        <Pie data={getPieChartData()} dataKey="value" nameKey="name" cx="50%" cy="50%" outerRadius={80} label />
        <Legend />
      </PieChart>
    </div>
  );
};

export default DashBoardChart;

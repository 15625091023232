import React, { useState } from 'react';
import axios from 'axios';
import './AdminFileUpload.css'; // Import CSS file for styling
import { FaUpload } from 'react-icons/fa';

const AdminFileUpload = () => {
  const [selectedFile, setSelectedFile] = useState(null);
  const [fileName, setFileName] = useState('');

  const handleFileChange = (e) => {
    setSelectedFile(e.target.files[0]);
    setFileName(e.target.files[0].name);
  };

  const handleUpload = async () => {
    try {
      const formData = new FormData();
      formData.append('file', selectedFile);

      // Send file to backend
      const response = await axios.post('https://apikey.intervuer.com/upload', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      });
     
      // Clear selected file after upload
      setSelectedFile(null);

      alert(response.data); // You can handle the response as neede
    } catch (error) {
      console.error('Error uploading file:', error);
    }
  };

  return (
    <div className="admin-file-upload-container">

      <div className="file-input-container">
        <input type="file" className='inputfile' onChange={handleFileChange} />

        {selectedFile && <p>Selected File: {fileName}</p>}
      </div>
      <button  className="upload-button" onClick={handleUpload}><FaUpload/>  Upload</button>
    </div>
  );
};

export default AdminFileUpload;

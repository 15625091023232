// App.js
import React from 'react';
import { BrowserRouter as Routes, Route, BrowserRouter,Outlet } from 'react-router-dom';
import Sidebar from './SieBar';

import './SideBar.css'
import DashBoardChart from './DashBoardCharts';
function App({color}) {

  return (

    <div className="D-container">
     
    <div className="D-sidebar" style={{backgroundColor:color}} >
     <Sidebar/>
     </div>
     <div className="D-content">
     <div className="profile">
      <div className="profile-image">
        {/* Placeholder for circular image */}
        <img src="https://via.placeholder.com/150" alt="Profile" />
      </div>
      <div className="profile-details">
        {/* Display user details here, e.g., username */}
      </div>
      <button className="logout-btn" >Logout</button>
    </div>

     <Outlet></Outlet>
       </div>  
 
    </div>
  );
}

export default App;

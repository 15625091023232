import React, { useState, useEffect } from 'react';
import './Leads.css'; // Import CSS file for styling
import { MdPerson, MdAdd, MdStar } from 'react-icons/md';
import { FaClipboardList } from 'react-icons/fa';
import axios from 'axios';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import { Modal, Button } from 'react-bootstrap';

const ScrollableTable = () => {
  const [callModalOpen, setCallModalOpen] = useState(false)
  const [userValues, setUserValues] = useState(Array(60).fill(''));

  
  const handleInputChange = (e, rowIndex) => {
    const { value } = e.target;
    if (value >= 0 && value <= 3) {
      const updatedUserValues = [...userValues];
      updatedUserValues[rowIndex] = value;
      setUserValues(updatedUserValues);
    } else {
      console.error('Value must be between 0 and 3');
    }
  };



  const [sheetData, setSheetData] = useState([]);
  const [MapData, setMapData] = useState([]);
  const [callsData ,setCalldata]=useState([])
  const [selectedRowData, setSelectedRowData] = useState(null);
  const [isProfileModalOpen, setIsProfileModalOpen] = useState(false);
  const [isScoreModalOpen, setScoreModalOpen] = useState(false);
  const [isClipModalOpen, setIClipModalOpen] = useState(false);
  const [editedData, setEditedData] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedRowIndex, setSelectedRowIndex] = useState(null); // New state to store the selected row index
  const [editedUserData, setEditedUserData] = useState([]);
  const Dropdown = ({ options, value, onChange }) => (
    <select value={value} onChange={onChange} className="form-control">
      {options.map((option, index) => (
        <option key={index} value={option}>
          {option}
        </option>
      ))}
    </select>
  );
  const getDropdownOptions = (rowIndex, cellIndex, cellValue) => {
    // Implement logic to generate options based on cellValue, rowIndex, and cellIndex
    // Example logic:
    if (rowIndex === 0 && cellIndex === 0) {
      return ['O1', 'O2', 'O3'];
    } else if (rowIndex === 0 && cellIndex === 1) {
      return ['Option X', 'Option Y', 'Option Z'];
    } else {
      switch (cellIndex) {
        case 36: // Assuming the cellIndex corresponds to the Status column
          return ['Call Connected', 'Call Not Connected', 'Call Not Connected - Multiple Times', 'Call Back', 'Not Recommended', 'Shortlisted', 'Not Interested', 'Follow Up Call', 'Freelance', 'DCA', 'Wrong Number', 'Company Interview Scheduled', 'Video Call Scheduled', 'Recruited'];
        case 37:
          return ['Excellent', 'Good', 'Average', 'Below Average'];
        case 12:
          return ['Male', 'Female', 'Other'];
        case 9:
          return ['Single', 'Married'];
        case 28:
          return ['Islamabad', 'Lahore', 'Rawalpindi', 'Karachi', 'Faisalabad', 'Multan', 'Other'];
        case 39:
          return ['Real', 'Estate	', 'Call Center', 'Banking', 'IT', 'Marketing', 'Hospitality', 'Other', ''];
        case 40:
          return ['Sales', 'HR	', 'Marketing', 'Legal', '	Accounts & Finance', 'Customer Care', 'Business Development', 'Other']
        case 41:
          return ['RE - Files & Plots', 'RE - High Rise', 'RE - Both Files & High Rise', 'CC - Inbound Sales', 'CC - Outbound Sales', 'RE - Rental', 'RE - Houses'];
        case 50:
          return ['Conducted', 'Didn`t Respond', 'Re-Schedule', 'Didn`t Appear']
        case 47:
          return ['IB02PRM023', 'IB04ZM043', 'IB05MBA083', 'LR07TP093'];
        default:
          return ['Avg 1', 'Excellent2', 'Good2'];
      }
    }
  };
  useEffect(() => {
    const fetchMapData = async () => {
      try {
        const response = await axios.get('https://apikey.intervuer.com/talent/mapss');
        setMapData(response.data);

      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchMapData();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get('https://apikey.intervuer.com/talent/data-recruite');
        setSheetData(response.data);
        // Initialize editedData with the same structure as sheetData
        setEditedData(response.data.map(row => [...row]));
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get('https://apikey.intervuer.com/talent/calls');
        setCalldata(response.data)
        
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);

  const handleSave = async () => {
    try {
      await axios.post('/talent/update', { values: editedData });
      // Refresh data after successful update
      const response = await axios.get('/talent/data-recruite');
      setSheetData(response.data);
      setEditedData(response.data.map(row => [...row]));
    } catch (error) {
      console.error('Error updating data:', error);
    }
  };
  const handleAddIconClick = (row) => {
    const rowIndex = sheetData.indexOf(row);
    setSelectedUser(row);
    setSelectedRowIndex(rowIndex);
    setIsModalOpen(true);
  };
  const handleProfileIconClick = (row) => {
    setSelectedRowData(row);
    setIsProfileModalOpen(true);
  };
  const handleClipBoard = (row) => {
    const rowIndex = sheetData.indexOf(row); // Get the index of the selected row
    setSelectedUser(row);
    setSelectedRowIndex(rowIndex);
    setIClipModalOpen(true);
  };
  const handleScoreModalClick = (row) => {
    const rowIndex = sheetData.indexOf(row);
    setSelectedUser(row);
    setSelectedRowIndex(rowIndex);
    if(row[39] === "Real Estate")
    {setScoreModalOpen(true)
    }
    else{
    setCallModalOpen(true)}
  };
  const handleScoreModalClose = () => {
    setScoreModalOpen(false)
  };
  const handleCallsModalClose = () => {
   setCallModalOpen(false)
  };

  const handleProfileModalClose = () => {
    setIsProfileModalOpen(false);
  };
  const handleClipClose = () => {
    setIClipModalOpen(false);
  };









  const handleModalSave = async () => {
    try {
      if (isModalOpen || isClipModalOpen) {

        const updatedEditedUserData = [...editedUserData];
        const updatedData = editedData.map(row => {
          if (row[0] === selectedUser[0]) {
            return updatedEditedUserData;
          }
          return row;
        });
        setEditedData(updatedData); 
        await axios.post('https://apikey.intervuer.com/talent/update', { values: updatedData });

        setIsModalOpen(false);
        setIClipModalOpen(false);
      }
    } catch (error) {
      console.error('Error updating data:', error);
    }
  };

  const getLabelForIndex = (index) => {
    switch (index) {
      case 0:
        return 'User ID ';
      case 1:
        return 'Lead Generation Date';
      case 2:
        return 'E-Mail'
      case 4:
        return 'Name ';
      case 5:
        return ' CNIC';
      case 6:
        return 'Contact Number'
      case 7:
        return 'Secondary Contact Number';
      case 8:
        return 'City'
      case 10:
        return 'Date of Birth';
      case 11:
        return 'Age'
      case 12:
        return 'Gender';
      case 13:
        return 'Domicile District';
      case 14:
        return 'Qualification';
      case 9:
        return 'Marital Status';
      case 18:
        return 'Experience in Sales';
      case 19:
        return 'Current Company';
      case 20:
        return 'Updated Company`s Name';
      case 21:
        return 'Last Job Duration';
      case 22:
        return 'Remarks about Last Company';
      case 23:
        return 'Remarks about Last Boss';
      case 24:
        return 'Current Salary (Per Month)';
      case 25:
        return 'Expected Salary (Per Month)';
      case 27:
        return 'Preferred Job Location';
      case 28:
        return 'Preferred City for Job';
      case 29:
        return 'Home Location';
      case 30:
        return 'Designation'
      case 31:
        return 'Updated Designation';
      case 36:
        return 'Status:';
      case 37:
        return 'Category:';
      case 38:
        return 'Remarks'
      case 39:
        return 'Industry';
      case 40:
        return 'Department';
      case 41:

        return 'Sale Domain';
      case 42:
        return 'Lead Source';
      case 47:
        return 'Company for Interview'
      case 45:
        return 'Follow Up on'
      case 50:
        return 'Company Interview Status'
      case 51:
        return 'Company Interview Re-Scheduled On ';
      case 49:
        return 'Company Interview Scheduled On'
      case 44:
        return 'Interview Conducted on';


      default:
        return '';
    }
  };


  const handleModalClose = () => {
    setIsModalOpen(false);
  };

  const handleModalInputChange = (e, index) => {
    const updatedUserData = [...editedUserData];
    updatedUserData[index] = e.target.value;
    setEditedUserData(updatedUserData);
  };
  const handleTraitModalSave = async () => {

    const calculatedValues = userValues.map((value, index) => {

      let divisor;
      switch (parseInt(value)) {
        case 1:
          divisor = 3;
          break;
        case 2:
          divisor = 1.5;
          break;
        case 3:
          divisor = 1;
          break;
        default:
          divisor = 0;
          break;
      }

      const calculatedValue = parseFloat(MapData[index + 1][5]) / divisor;
      return calculatedValue;
    });

    const tOne = calculatedValues.slice(0, 5);
    const tTwo = calculatedValues.slice(5, 8);
    const tThree = calculatedValues.slice(8, 11);
    const tFour = calculatedValues.slice(11, 14);
    const tFive = calculatedValues[14];
    const tSix = calculatedValues.slice(15, 18);
    const tSeven = calculatedValues.slice(18, 19);
    const tEight = calculatedValues.slice(19, 21);
    const tNine = calculatedValues.slice(21, 25);
    const tTen = calculatedValues.slice(25, 28);
    const tEleven = calculatedValues.slice(28, 31);
    const tTwelve = calculatedValues.slice(31, 33);
    const tThirteen = calculatedValues.slice(33, 36);
    const tFourteen = calculatedValues.slice(36, 38);
    const tFifteen = calculatedValues.slice(38, 43);
    const tSixteen = calculatedValues.slice(43, 45);
    const tSeventeen = calculatedValues.slice(45, 47);
    const tEighteen = calculatedValues.slice(47, 50);
    const tNineteen = calculatedValues.slice(50, 52);
    const tTwenty = calculatedValues.slice(52, 56);
    const tTwentyOne = calculatedValues.slice(56, 58);
    const tTwentyTwo = calculatedValues.slice(58, 60);

    const sumOne = tOne.reduce((total, value) => total + value, 0);
    const sumTwo = tTwo.reduce((total, value) => total + value, 0);
    const sumThree = tThree.reduce((total, value) => total + value, 0);
    const sumFour = tFour.reduce((total, value) => total + value, 0);
    const sumFive = tFive;
    const sumSix = tSix.reduce((total, value) => total + value, 0);
    const sumSeven = tSeven.reduce((total, value) => total + value, 0);
    const sumEight = tEight.reduce((total, value) => total + value, 0);
    const sumNine = tNine.reduce((total, value) => total + value, 0);
    const sumTen = tTen.reduce((total, value) => total + value, 0);
    const sumEleven = tEleven.reduce((total, value) => total + value, 0);
    const sumTwelve = tTwelve.reduce((total, value) => total + value, 0);
    const sumThirteen = tThirteen.reduce((total, value) => total + value, 0);
    const sumFourteen = tFourteen.reduce((total, value) => total + value, 0);
    const sumFifteen = tFifteen.reduce((total, value) => total + value, 0);
    const sumSixteen = tSixteen.reduce((total, value) => total + value, 0);
    const sumSeventeen = tSeventeen.reduce((total, value) => total + value, 0);
    const sumEighteen = tEighteen.reduce((total, value) => total + value, 0);
    const sumNineteen = tNineteen.reduce((total, value) => total + value, 0);
    const sumTwenty = tTwenty.reduce((total, value) => total + value, 0);
    const sumTwentyOne = tTwentyOne.reduce((total, value) => total + value, 0);
    const sumTwentyTwo = tTwentyTwo.reduce((total, value) => total + value, 0);
    
    const sumTotal = sumOne + sumTwo + sumThree + sumFour + sumFive + sumSix + sumSeven + sumEight + sumNine + sumTen + sumEleven + sumTwelve + sumThirteen + sumFourteen + sumFifteen + sumSixteen + sumSeventeen + sumEighteen + sumNineteen + sumTwenty + sumTwentyOne + sumTwentyTwo;
    const totalItems = 220; // Assuming 220 is the total number of items
    
    // Calculate cumulative percentage
    const cumulativePercentage = (sumTotal / (totalItems)) * 100;
    
    // If the percentage is greater than 100, set it to 100 (for correctness)
    const percentCorrected = Math.min(cumulativePercentage, 100);
    
    try {
      if (callModalOpen || isScoreModalOpen) {
   
        const updatedEditedUserData = [...editedUserData];
        updatedEditedUserData[53] = sumOne;
        updatedEditedUserData[54] = sumTwo;
        updatedEditedUserData[55] = sumThree;
        updatedEditedUserData[56] = sumFour;
        updatedEditedUserData[57] = sumFive;
        updatedEditedUserData[58] = sumSix;
        updatedEditedUserData[59] = sumSeven;
        updatedEditedUserData[60] = sumEight;
        updatedEditedUserData[61] = sumNine;
        updatedEditedUserData[62] = sumTen;
        updatedEditedUserData[63] = sumEleven;
        updatedEditedUserData[64] = sumTwelve;
        updatedEditedUserData[65] = sumThirteen;
        updatedEditedUserData[66] = sumFourteen;
        updatedEditedUserData[67] = sumFifteen;
        updatedEditedUserData[68] = sumSixteen;
        updatedEditedUserData[69] = sumSeventeen;
        updatedEditedUserData[70] = sumEighteen;
        updatedEditedUserData[71] = sumNineteen;
        updatedEditedUserData[72] = sumTwenty;
        updatedEditedUserData[73] = sumTwentyOne;
        updatedEditedUserData[74] = sumTwentyTwo;


        const updatedData = editedData.map(row => {
          if (row[0] === selectedUser[0]) {
            return updatedEditedUserData;
          }
          return row;
        });
        setEditedData(updatedData); // Update editedData here

        // Save changes to the database directly
        await axios.post('/talent/update', { values: updatedData });
        setScoreModalOpen(false);

      }
    } catch (error) {
      console.error('Error updating data:', error);
    }
  };
   const handleTraitCallsModalSave = async () => {

    const calculatedValues = userValues.map((value, index) => {

      let divisor;
      switch (parseInt(value)) {
        case 1:
          divisor = 3;
          break;
        case 2:
          divisor = 1.5;
          break;
        case 3:
          divisor = 1;
          break;
        default:
          divisor = 0;
          break;
      }

      const calculatedValue = parseFloat(callsData[index + 1][5]) / divisor;
      return calculatedValue;
    });

    const tOne = calculatedValues.slice(0, 5);
    const tTwo = calculatedValues.slice(5, 8);
    const tThree = calculatedValues.slice(8, 11);
    const tFour = calculatedValues.slice(11, 14);
    const tFive = calculatedValues[14];
    const tSix = calculatedValues.slice(15, 18);
    const tSeven = calculatedValues.slice(18, 19);
    const tEight = calculatedValues.slice(19, 21);
    const tNine = calculatedValues.slice(21, 25);
    const tTen = calculatedValues.slice(25, 28);
    const tEleven = calculatedValues.slice(28, 31);
    const tTwelve = calculatedValues.slice(31, 33);
    const tThirteen = calculatedValues.slice(33, 36);
    const tFourteen = calculatedValues.slice(36, 38);
    const tFifteen = calculatedValues.slice(38, 43);
    const tSixteen = calculatedValues.slice(43, 45);
    const tSeventeen = calculatedValues.slice(45, 47);
    const tEighteen = calculatedValues.slice(47, 50);
    const tNineteen = calculatedValues.slice(50, 52);
    const tTwenty = calculatedValues.slice(52, 56);
    const tTwentyOne = calculatedValues.slice(56, 58);
    const tTwentyTwo = calculatedValues.slice(58, 60);

    const sumOne = tOne.reduce((total, value) => total + value, 0);
    const sumTwo = tTwo.reduce((total, value) => total + value, 0);
    const sumThree = tThree.reduce((total, value) => total + value, 0);
    const sumFour = tFour.reduce((total, value) => total + value, 0);
    const sumFive = tFive;
    const sumSix = tSix.reduce((total, value) => total + value, 0);
    const sumSeven = tSeven.reduce((total, value) => total + value, 0);
    const sumEight = tEight.reduce((total, value) => total + value, 0);
    const sumNine = tNine.reduce((total, value) => total + value, 0);
    const sumTen = tTen.reduce((total, value) => total + value, 0);
    const sumEleven = tEleven.reduce((total, value) => total + value, 0);
    const sumTwelve = tTwelve.reduce((total, value) => total + value, 0);
    const sumThirteen = tThirteen.reduce((total, value) => total + value, 0);
    const sumFourteen = tFourteen.reduce((total, value) => total + value, 0);
    const sumFifteen = tFifteen.reduce((total, value) => total + value, 0);
    const sumSixteen = tSixteen.reduce((total, value) => total + value, 0);
    const sumSeventeen = tSeventeen.reduce((total, value) => total + value, 0);
    const sumEighteen = tEighteen.reduce((total, value) => total + value, 0);
    const sumNineteen = tNineteen.reduce((total, value) => total + value, 0);
    const sumTwenty = tTwenty.reduce((total, value) => total + value, 0);
    const sumTwentyOne = tTwentyOne.reduce((total, value) => total + value, 0);
    const sumTwentyTwo = tTwentyTwo.reduce((total, value) => total + value, 0);
    
    const sumTotal = sumOne + sumTwo + sumThree + sumFour + sumFive + sumSix + sumSeven + sumEight + sumNine + sumTen + sumEleven + sumTwelve + sumThirteen + sumFourteen + sumFifteen + sumSixteen + sumSeventeen + sumEighteen + sumNineteen + sumTwenty + sumTwentyOne + sumTwentyTwo;
    const totalItems = 220; // Assuming 220 is the total number of items
    
    // Calculate cumulative percentage
    const cumulativePercentage = (sumTotal / (totalItems)) * 100;
    
    // If the percentage is greater than 100, set it to 100 (for correctness)
    const percentCorrected = Math.min(cumulativePercentage, 100);
    
    try {
      if (callModalOpen || isScoreModalOpen) {
   
        const updatedEditedUserData = [...editedUserData];
        updatedEditedUserData[53] = sumOne;
        updatedEditedUserData[54] = sumTwo;
        updatedEditedUserData[55] = sumThree;
        updatedEditedUserData[56] = sumFour;
        updatedEditedUserData[57] = sumFive;
        updatedEditedUserData[58] = sumSix;
        updatedEditedUserData[59] = sumSeven;
        updatedEditedUserData[60] = sumEight;
        updatedEditedUserData[61] = sumNine;
        updatedEditedUserData[62] = sumTen;
        updatedEditedUserData[63] = sumEleven;
        updatedEditedUserData[64] = sumTwelve;
        updatedEditedUserData[65] = sumThirteen;
        updatedEditedUserData[66] = sumFourteen;
        updatedEditedUserData[67] = sumFifteen;
        updatedEditedUserData[68] = sumSixteen;
        updatedEditedUserData[69] = sumSeventeen;
        updatedEditedUserData[70] = sumEighteen;
        updatedEditedUserData[71] = sumNineteen;
        updatedEditedUserData[72] = sumTwenty;
        updatedEditedUserData[73] = sumTwentyOne;
        updatedEditedUserData[74] = sumTwentyTwo;


        const updatedData = editedData.map(row => {
          if (row[0] === selectedUser[0]) {
            return updatedEditedUserData;
          }
          return row;
        });
        setEditedData(updatedData); // Update editedData here

        // Save changes to the database directly
        await axios.post('https://apikey.intervuer.com/talent/update', { values: updatedData });
        setCallModalOpen(false)

      }
    } catch (error) {
      console.error('Error updating data:', error);
    }
  };

  return (
    <div className="scrollable-table-container">
      <div className="sticky-header">
        <table>
          <thead>
            <tr>
              <th style={{ minWidth: '130px', position: 'sticky', left: 0, zIndex: 1, backgroundColor: '#f8f8f8' }}>Sr. No.</th>
              <th style={{ minWidth: '130px', position: 'sticky', left: '130px', zIndex: 1, backgroundColor: '#f8f8f8' }}>User ID</th>
              <th style={{ minWidth: '130px', position: 'sticky', left: '260px', zIndex: 1, backgroundColor: '#f8f8f8' }}>Name</th>
              <th style={{ minWidth: '130px', position: 'sticky', left: '390px', zIndex: 1, backgroundColor: '#f8f8f8' }}>Contact</th>
              <th style={{ minWidth: '130px', backgroundColor: '#f8f8f8' }}>City</th>
              <th style={{ minWidth: '130px', backgroundColor: '#f8f8f8' }}>Status</th>
              <th style={{ minWidth: '130px', backgroundColor: '#f8f8f8' }}>Category</th>
              <th style={{ minWidth: '130px', backgroundColor: '#f8f8f8' }}>Lead <br />Gen. Date</th>
              <th style={{ minWidth: '130px', backgroundColor: '#f8f8f8' }}>Current Company</th>
              <th style={{ minWidth: '130px', backgroundColor: '#f8f8f8' }}>Sales Experience</th>
              <th style={{ minWidth: '130px', backgroundColor: '#f8f8f8' }}>Designation</th>
              <th style={{ minWidth: '130px', backgroundColor: '#f8f8f8' }}>MAPSS <br /> Score</th>
              <th style={{ minWidth: '130px', backgroundColor: '#f8f8f8' }}>Lead Assigned To</th>
              <th style={{ minWidth: '130px', position: 'sticky', right: '0px', zIndex: 1, backgroundColor: '#f8f8f8' }}>Tasks</th>
            </tr>
          </thead>
        </table>
      </div>
      <table>
        <tbody>
          {sheetData.slice(2).map((row, rowIndex) => (
            <tr key={rowIndex}>
              {/* Render fixed columns */}
              <td style={{ minWidth: '130px', position: 'sticky', left: 0, zIndex: 1, backgroundColor: '#f8f8f8' }}>
                {row[0]} {/* Assuming row[0] corresponds to the first fixed column */}
              </td>
              <td style={{ minWidth: '130px', position: 'sticky', left: '130px', zIndex: 1, backgroundColor: '#f8f8f8' }}>
                {row[-2]} {/* Assuming row[-2] corresponds to the second fixed column */}
              </td>
              <td style={{ minWidth: '130px', position: 'sticky', left: '260px', zIndex: 1, backgroundColor: '#f8f8f8' }}>
                {row[4]} {/* Assuming row[4] corresponds to the third fixed column */}
              </td>
              <td style={{ minWidth: '130px', position: 'sticky', left: '390px', zIndex: 1, backgroundColor: '#f8f8f8' }}>
                {row[5]} {/* Assuming row[5] corresponds to the fourth fixed column */}
              </td>

              {/* Render remaining columns */}
              {[row[8], row[36], row[37], row[1], row[19], row[18], row[30], row[52], row[43]].map((cell, cellIndex) => (
                <td key={cellIndex} style={{ minWidth: '130px' }}>
                  {cell}
                </td>
              ))}
              <td style={{ minWidth: '130px', position: 'sticky', right: '0px', zIndex: 1, backgroundColor: '#f8f8f8' }}>
                <MdPerson className='mx-2' style={{ color: 'blue', cursor: 'pointer' }} onClick={() => handleProfileIconClick(row)} /> {/* Profile Icon */}
                <MdAdd
                  className="mx-2"
                  style={{ color: 'blue', cursor: 'pointer' }}
                  onClick={() => handleAddIconClick(row)}
                />
                {/* Add Icon */}
                <MdStar className='mx-2' style={{ color: 'blue', cursor: 'pointer' }} onClick={() => handleScoreModalClick(row)} />
                <FaClipboardList style={{ color: 'blue', cursor: 'pointer' }} onClick={() => handleClipBoard(row)} />
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      {isClipModalOpen && (
        <Modal show={isClipModalOpen} onHide={handleClipClose}>
          <Modal.Header closeButton>
            <Modal.Title>Remarks</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div >

              <div >
                {/* Display non-editable data */}
                {selectedRowIndex !== null &&
                  sheetData[selectedRowIndex].map((cell, index) => (
                    (index === 22 || index === 23 || index === 38) && (
                      <div key={index} className="d-flex align-items-center mb-2">
                        <b style={{ minWidth: '200px' }}><span>{getLabelForIndex(index)}:</span></b>
                        <span style={{ border: '0.3px solid lightgray', padding: '5px' }}>{cell}</span>
                      </div>
                    )
                  ))}

              </div>
              <div className="input-container"> <br />
                {selectedRowIndex !== null &&
                  sheetData[selectedRowIndex].map((cell, index) => (
                    (index === 38) ? ( // For date index 25
                      <div key={index} className="d-flex align-items-center mb-2">

                        <span>Update {getLabelForIndex(index)}</span>
                        <input
                          type="text"
                          value={editedUserData[index] || ''}
                          onChange={(e) => handleModalInputChange(e, index)}
                        />
                      </div>
                    ) : null
                  ))}
              </div>

            </div>
          </Modal.Body>

          <Modal.Footer>
            <Button variant="secondary" onClick={handleClipClose}>
              Close
            </Button>
            <Button variant="primary" onClick={handleModalSave}>
              Save Changes
            </Button>
          </Modal.Footer>
        </Modal>
      )}
      {isScoreModalOpen && (
        <Modal show={isScoreModalOpen} onHide={handleScoreModalClose}>
          <Modal.Header closeButton>
            <Modal.Title>Real Estate Score</Modal.Title>
          </Modal.Header>
          <Modal.Body style={{ maxHeight: '400px', overflowY: 'auto' }}>
            {/* Table for input fields */}
            <table className="table">
              <tbody>
                {MapData.slice(1).map((rw, rowIndex) => (
                  <tr key={rowIndex}>
                    {[rw[0], rw[2],rw[5]].map((cell, cellIndex) => (
                      <td key={cellIndex} style={{ maxWidth: '650px', border: '0.2px solid lightgray', padding: '5px', textAlign: 'start' }}>
                        <span>{cell}</span>
                      </td>
                    ))}
                    <td style={{ maxWidth: '650px', border: '0.2px solid lightgray', padding: '5px' }}>
                      <input
                        type="number"
                        value={userValues[rowIndex] || ''}
                        onChange={(e) => handleInputChange(e, rowIndex)}
                        min="0"
                        max="3"
                      />
                    </td>
                  </tr>
                ))}
                
              </tbody>
            </table>

            {/* Table to display calculated values */}

          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleScoreModalClose}>
              Close
            </Button>
            <Button variant="primary" onClick={handleTraitModalSave}>
              Save Changes
            </Button>
          </Modal.Footer>
        </Modal>
      )}
        {callModalOpen && (
        <Modal show={callModalOpen} onHide={handleCallsModalClose}>
          <Modal.Header closeButton>
            <Modal.Title>Call Center Score</Modal.Title>
          </Modal.Header>
          <Modal.Body style={{ maxHeight: '400px', overflowY: 'auto' }}>
          <table className="table">
              <tbody>
                {callsData.slice(1).map((rw, rowIndex) => (
                  <tr key={rowIndex}>
                    {[rw[0], rw[2],  rw[5]].map((cell, cellIndex) => (
                      <td key={cellIndex} style={{ maxWidth: '650px', border: '0.2px solid lightgray', padding: '5px', textAlign: 'start' }}>
                        <span>{cell}</span>
                      </td>
                    ))}
                    <td style={{ maxWidth: '650px', border: '0.2px solid lightgray', padding: '5px' }}>
                      <input
                        type="number"
                        value={userValues[rowIndex] || ''}
                        onChange={(e) => handleInputChange(e, rowIndex)}
                        min="0"
                        max="3"
                      />
                    </td>
                  </tr>
                ))}
                
              </tbody>
            </table>

            {/* Table to display calculated values */}

          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleCallsModalClose}>
              Close
            </Button>
            <Button variant="primary" onClick={handleTraitCallsModalSave}>
              Save Changes
            </Button>
          </Modal.Footer>
        </Modal>
      )}


      {isProfileModalOpen && (
        <Modal show={isProfileModalOpen} onHide={handleProfileModalClose}>
          <Modal.Header closeButton>
            <Modal.Title>User Profile</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {selectedRowData && (
              <div>
                {/* Render all data of the selected row */}
                <div className='row'>
                  <div className='col-6' style={{ maxHeight: '400px', overflowY: 'auto', fontSize: '0.8rem' }}>Personal<p> <b>User ID</b> {selectedRowData[0]}</p>
                    <p><b>Name :</b> {selectedRowData[4]}</p>
                    <p><b>CNIC :</b> {selectedRowData[5]}</p>
                    <p><b>Date of Birth :</b> {selectedRowData[10]}</p>
                    <p><b>Age :</b> {selectedRowData[11]}</p>
                    <p><b>Gender : </b>{selectedRowData[12]}</p>
                    <p><b>Marital Status :</b> {selectedRowData[9]}</p>
                    <p><b>Domicile District :</b> {selectedRowData[13]}</p>
                    <p><b>City:</b> {selectedRowData[8]}</p>
                    <p><b>Contact Number :</b> {selectedRowData[6]}</p>
                    <p><b>Secondary Contact Number :</b> {selectedRowData[7]}</p>
                    <p><b>Qualification :</b> {selectedRowData[14]}</p>
                    <p><b>Home Location :</b> {selectedRowData[29]}</p>
                    <p><b>E-Mail:</b> {selectedRowData[2]}</p>

                  </div>
                  <div className='col-6' style={{ maxHeight: '400px', overflowY: 'auto', fontSize: '0.8rem' }}>Professional
                    <p><b>Current Company :</b> {selectedRowData[19]}</p>
                    <p><b>Designation :</b> {selectedRowData[30]}</p>
                    <p><b>Experience in Sales :</b> {selectedRowData[18]}</p>
                    <p><b>Last Job Duration :</b> {selectedRowData[21]}</p>
                    <p><b>Remarks about Last Company : </b>{selectedRowData[22]}</p>
                    <p><b>Remarks about Last Boss :</b> {selectedRowData[23]}</p>
                    <p><b>Lead Generation Date :</b> {selectedRowData[1]}</p>
                    <p><b>Lead Source :</b> {selectedRowData[42]}</p>
                    <p><b>Status :</b> {selectedRowData[36]}</p>
                    <p><b>Call Interview Conducted On :</b> {selectedRowData[44]}</p>
                    <p><b>Follow-Up On :</b> {selectedRowData[45]}</p>
                    <p><b>Resource Category :</b> {selectedRowData[37]}</p>
                    <p><b>Industry :</b> {selectedRowData[39]}</p>
                    <p><b>Department :</b> {selectedRowData[40]}</p>
                    <p><b>Sales Domain :</b> {selectedRowData[41]}</p>
                    <p><b>Company For Interview :</b> {selectedRowData[47]}</p>
                    <p><b>Company Interview Scheduled On :</b> {selectedRowData[49]}</p>
                    <p><b>Remarks :</b> {selectedRowData[38]}</p>
                    <p><b>Company Interview Status :</b> {selectedRowData[50]}</p>
                    <p><b>Company Interview Re-Scheduled On :</b> {selectedRowData[51]} </p>
                    <p><b>Current Salary (Per Month):</b> {selectedRowData[24]}</p>
                    <p><b>Offered Salary : </b> {selectedRowData[48]} </p>
                    <p><b>Preferred City for Job :</b> {selectedRowData[28]}</p>
                    <p><b>Preferred Job Location :</b> {selectedRowData[27]}</p>
                  </div>
                </div>

                {/* Add more fields as needed */}
              </div>
            )}
          </Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleProfileModalClose}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      )}
      {isModalOpen && (
        <Modal show={isModalOpen} onHide={handleModalClose}>
          <Modal.Header closeButton>
            <Modal.Title>Update Data</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className='row'>
              {/* Update Data section */}
              <div className="update-data-section col-6">

                {/* Input fields */}
                <div className="input-container"> <b>Update Data</b> <br />
                  {selectedRowIndex !== null &&
                    sheetData[selectedRowIndex].map((cell, index) => (
                      (index === 2) ? ( // For date index 25
                        <div key={index} className="d-flex align-items-center mb-2">
                          <span>{getLabelForIndex(index)}</span>
                          <input
                            type="email"
                            value={editedUserData[index] || ''}
                            onChange={(e) => handleModalInputChange(e, index)}
                          />
                        </div>
                      ) : (index === 5) ? ( // For date index 25
                        <div key={index} className="d-flex align-items-center mb-2">
                          <span>{getLabelForIndex(index)}</span>
                          <input
                            type="number"
                            value={editedUserData[index] || ''}
                            onChange={(e) => handleModalInputChange(e, index)}
                          />
                        </div>
                      ) : (index === 7) ? (
                        <div key={index} className="d-flex align-items-center mb-2">
                          <span>{getLabelForIndex(index)}</span>
                          <input
                            type="text"
                            value={editedUserData[index] || ''}
                            onChange={(e) => handleModalInputChange(e, index)}
                          />
                        </div>
                      ) : (index === 8) ? (
                        <div key={index} className="d-flex align-items-center mb-2">
                          <span>{getLabelForIndex(index)}</span>
                          <input
                            type="text"
                            value={editedUserData[index] || ''}
                            onChange={(e) => handleModalInputChange(e, index)}
                          />
                        </div>
                      ) : (index === 9) ? (
                        <div key={index} className="d-flex align-items-center mb-2">
                          <span>{getLabelForIndex(index)}</span>
                          <Dropdown
                            key={index}
                            options={getDropdownOptions(selectedRowIndex, index, cell)}
                            value={editedUserData[index] || cell}
                            onChange={(e) => handleModalInputChange(e, index)}
                          />
                        </div>
                      ) : (index === 10) ? (
                        <div key={index} className="d-flex align-items-center mb-2">
                          <span>{getLabelForIndex(index)}</span>
                          <input
                            type="date"
                            value={editedUserData[index] || ''}
                            onChange={(e) => handleModalInputChange(e, index)}
                          />
                        </div>
                      ) : (index === 11) ? (
                        <div key={index} className="d-flex align-items-center mb-2">
                          <span>{getLabelForIndex(index)}</span>
                          <input
                            type="number"
                            value={editedUserData[index] || ''}
                            onChange={(e) => handleModalInputChange(e, index)}
                          />
                        </div>
                      ) : (index === 12) ? (
                        <div key={index} className="d-flex align-items-center mb-2">
                          <span>{getLabelForIndex(index)}</span>
                          <Dropdown
                            key={index}
                            options={getDropdownOptions(selectedRowIndex, index, cell)}
                            value={editedUserData[index] || cell}
                            onChange={(e) => handleModalInputChange(e, index)}
                          />
                        </div>
                      ) : (index === 13) ? (
                        <div key={index} className="d-flex align-items-center mb-2">
                          <span>{getLabelForIndex(index)}</span>
                          <input
                            type="text"
                            value={editedUserData[index] || ''}
                            onChange={(e) => handleModalInputChange(e, index)}
                          />
                        </div>
                      ) : (index === 14) ? (
                        <div key={index} className="d-flex align-items-center mb-2">
                          <span>{getLabelForIndex(index)}</span>
                          <input
                            type="text"
                            value={editedUserData[index] || ''}
                            onChange={(e) => handleModalInputChange(e, index)}
                          />
                        </div>
                      ) : (index === 20) ? (
                        <div key={index} className="d-flex align-items-center mb-2">
                          <span>{getLabelForIndex(index)}</span>
                          <input
                            type="text"
                            value={editedUserData[index] || ''}
                            onChange={(e) => handleModalInputChange(e, index)}
                          />
                        </div>
                      ) : (index === 21) ? (
                        <div key={index} className="d-flex align-items-center mb-2">
                          <span>{getLabelForIndex(index)}</span>
                          <input
                            type="text"
                            value={editedUserData[index] || ''}
                            onChange={(e) => handleModalInputChange(e, index)}
                          />
                        </div>
                      ) : (index === 22) ? (
                        <div key={index} className="d-flex align-items-center mb-2">
                          <span>{getLabelForIndex(index)}</span>
                          <input
                            type="text"
                            value={editedUserData[index] || ''}
                            onChange={(e) => handleModalInputChange(e, index)}
                          />
                        </div>
                      ) : (index === 23) ? (
                        <div key={index} className="d-flex align-items-center mb-2">
                          <span>{getLabelForIndex(index)}</span>
                          <input
                            type="text"
                            value={editedUserData[index] || ''}
                            onChange={(e) => handleModalInputChange(e, index)}
                          />
                        </div>
                      ) : (index === 24) ? (
                        <div key={index} className="d-flex align-items-center mb-2">
                          <span>{getLabelForIndex(index)}</span>
                          <input
                            type="number"
                            value={editedUserData[index] || ''}
                            onChange={(e) => handleModalInputChange(e, index)}
                          />
                        </div>
                      ) : (index === 25) ? (
                        <div key={index} className="d-flex align-items-center mb-2">
                          <span>{getLabelForIndex(index)}</span>
                          <input
                            type="number"
                            value={editedUserData[index] || ''}
                            onChange={(e) => handleModalInputChange(e, index)}
                          />
                        </div>
                      ) : (index === 27) ? (
                        <div key={index} className="d-flex align-items-center mb-2">
                          <span>{getLabelForIndex(index)}</span>
                          <input
                            type="text"
                            value={editedUserData[index] || ''}
                            onChange={(e) => handleModalInputChange(e, index)}
                          />
                        </div>
                      ) : (index === 28) ? (
                        <div key={index} className="d-flex align-items-center mb-2">
                          <span>{getLabelForIndex(index)}</span>
                          <Dropdown
                            key={index}
                            options={getDropdownOptions(selectedRowIndex, index, cell)}
                            value={editedUserData[index] || cell}
                            onChange={(e) => handleModalInputChange(e, index)}
                          />
                        </div>
                      ) : (index === 29) ? (
                        <div key={index} className="d-flex align-items-center mb-2">
                          <span>{getLabelForIndex(index)}</span>
                          <input
                            type="text"
                            value={editedUserData[index] || ''}
                            onChange={(e) => handleModalInputChange(e, index)}
                          />
                        </div>
                      ) : (index === 30) ? (
                        <div key={index} className="d-flex align-items-center mb-2">
                          <span>{getLabelForIndex(index)}</span>
                          <input
                            type="text"
                            value={editedUserData[index] || ''}
                            onChange={(e) => handleModalInputChange(e, index)}
                          />
                        </div>
                      ) : (index === 31) ? (
                        <div key={index} className="d-flex align-items-center mb-2">
                          <span>{getLabelForIndex(index)}</span>
                          <input
                            type="text"
                            value={editedUserData[index] || ''}
                            onChange={(e) => handleModalInputChange(e, index)}
                          />
                        </div>
                      ) : (index === 36) ? (
                        <div key={index} className="d-flex align-items-center mb-2">
                          <span>{getLabelForIndex(index)}</span>
                          <Dropdown
                            key={index}
                            options={getDropdownOptions(selectedRowIndex, index, cell)}
                            value={editedUserData[index] || cell}
                            onChange={(e) => handleModalInputChange(e, index)}
                          />
                        </div>
                      ) : (index === 37) ? (
                        <div key={index} className="d-flex align-items-center mb-2">
                          <span>{getLabelForIndex(index)}</span>
                          <Dropdown
                            key={index}
                            options={getDropdownOptions(selectedRowIndex, index, cell)}
                            value={editedUserData[index] || cell}
                            onChange={(e) => handleModalInputChange(e, index)}
                          />
                        </div>
                      ) : (index === 38) ? (
                        <div key={index} className="d-flex align-items-center mb-2">
                          <span>{getLabelForIndex(index)}</span>
                          <input
                            type="text"
                            value={editedUserData[index] || ''}
                            onChange={(e) => handleModalInputChange(e, index)}
                          />
                        </div>
                      ) : (index === 39) ? ( // For dropdown index 37
                        <div key={index} className="d-flex align-items-center mb-2">
                          <span>{getLabelForIndex(index)}</span>
                          <Dropdown
                            key={index}
                            options={getDropdownOptions(selectedRowIndex, index, cell)}
                            value={editedUserData[index] || cell}
                            onChange={(e) => handleModalInputChange(e, index)}
                          />
                        </div>
                      ) : (index === 40) ? ( // For dropdown index 37
                        <div key={index} className="d-flex align-items-center mb-2">
                          <span>{getLabelForIndex(index)}</span>
                          <Dropdown
                            key={index}
                            options={getDropdownOptions(selectedRowIndex, index, cell)}
                            value={editedUserData[index] || cell}
                            onChange={(e) => handleModalInputChange(e, index)}
                          />
                        </div>
                      ) : (index === 41) ? ( // For dropdown index 37
                        <div key={index} className="d-flex align-items-center mb-2">
                          <span>{getLabelForIndex(index)}</span>
                          <Dropdown
                            key={index}
                            options={getDropdownOptions(selectedRowIndex, index, cell)}
                            value={editedUserData[index] || cell}
                            onChange={(e) => handleModalInputChange(e, index)}
                          />
                        </div>
                      ) : (index === 44) ? (
                        <div key={index} className="d-flex align-items-center mb-2">
                          <span>{getLabelForIndex(index)}</span>
                          <input
                            type="date"
                            value={editedUserData[index] || ''}
                            onChange={(e) => handleModalInputChange(e, index)}
                          />
                        </div>
                      ) : (index === 45) ? (
                        <div key={index} className="d-flex align-items-center mb-2">
                          <span>{getLabelForIndex(index)}</span>
                          <input
                            type="datetime-local"
                            value={editedUserData[index] || ''}
                            onChange={(e) => handleModalInputChange(e, index)}
                          />
                        </div>
                      ) : (index === 47) ? ( // For dropdown index 37
                        <div key={index} className="d-flex align-items-center mb-2">
                          <span>{getLabelForIndex(index)}</span>
                          <Dropdown
                            key={index}
                            options={getDropdownOptions(selectedRowIndex, index, cell)}
                            value={editedUserData[index] || cell}
                            onChange={(e) => handleModalInputChange(e, index)}
                          />
                        </div>
                      ) : (index === 49) ? (
                        <div key={index} className="d-flex align-items-center mb-2">
                          <span>{getLabelForIndex(index)}</span>
                          <input
                            type="datetime-local"
                            value={editedUserData[index] || ''}
                            onChange={(e) => handleModalInputChange(e, index)}
                          />
                        </div>
                      ) : (index === 50) ? ( // For dropdown index 37
                        <div key={index} className="d-flex align-items-center mb-2">
                          <span>{getLabelForIndex(index)}</span>
                          <Dropdown
                            key={index}
                            options={getDropdownOptions(selectedRowIndex, index, cell)}
                            value={editedUserData[index] || cell}
                            onChange={(e) => handleModalInputChange(e, index)}
                          />
                        </div>
                      ) : (index === 51) ? (
                        <div key={index} className="d-flex align-items-center mb-2">
                          <span>{getLabelForIndex(index)}</span>
                          <input
                            type="datetime-local"
                            value={editedUserData[index] || ''}
                            onChange={(e) => handleModalInputChange(e, index)}
                          />
                        </div>
                      ) : null
                    ))}
                </div>
              </div>
              {/* Personal Profile section */}
              <div className="personal-profile-section col-6">
                <b>Personal Profile</b>
                {/* Display non-editable data */}
                {selectedRowIndex !== null &&
                  sheetData[selectedRowIndex].map((cell, index) => (
                    (index === 0 || index === 1 || index === 4 || index === 6 || index === 19 || index === 18 || index === 42) && (
                      <div key={index} className="d-flex align-items-center mb-2">
                        <b><span>{getLabelForIndex(index)}:</span></b>
                        <span>{cell}</span>
                      </div>
                    )
                  ))}
                <Button variant="danger" >
                  Score
                </Button>
              </div>

            </div>
          </Modal.Body>

          <Modal.Footer>
            <Button variant="secondary" onClick={handleModalClose}>
              Close
            </Button>
            <Button variant="primary" onClick={handleModalSave}>
              Save Changes
            </Button>
          </Modal.Footer>
        </Modal>
      )}


    </div>
  );
};

export default ScrollableTable;

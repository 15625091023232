import React, { useState, useEffect } from 'react';
import '..//TalentAq/TabsCom/Leads.css'; // Import CSS file for styling
import axios from 'axios'
const Interviews = () => {
  
  const [sheetData, setSheetData] = useState([]);
  
  useEffect(() => {
    const fetchData = async () => {
        try {
          const response = await axios.get('https://apikey.intervuer.com/talent/data-recruite');
      
          const filteredData = response.data.filter(row => {
            const interviewStatus = row[36]; // Assuming interview status is in the 36th column of each row
            return interviewStatus === "Company's Interview Scheduled";
          });
      
          setSheetData(filteredData);
        } catch (error) {
          console.error('Error fetching data:', error);
        }
      };
      
  
    fetchData();
    const interval = setInterval(fetchData, 1000);
  
    return () => clearInterval(interval);
  }, []);

  
  return (
    
    <div className="scrollable-table-container">
        <h3 className='my-4' style={{  position: 'sticky', left: 0, zIndex: 1, }}>Interviews</h3>
    <div className="sticky-header">
      <table>
        <thead>
          <tr>
            <th style={{ minWidth: '130px', position: 'sticky', left: 0, zIndex: 1, backgroundColor:'#f9f9f9', }}>Sr. No.</th>
            <th style={{ minWidth: '130px', position: 'sticky', left: '130px', zIndex: 1, backgroundColor:'#f9f9f9', }}>User ID</th>
            <th style={{ minWidth: '130px', position: 'sticky', left: '260px', zIndex: 1, backgroundColor:'#f9f9f9', }}>Name</th>
            <th style={{ minWidth: '130px', position: 'sticky', left: '390px', zIndex: 1, backgroundColor:'#f9f9f9', }}>Contact</th>
            <th style={{ minWidth: '130px',backgroundColor:'#f9f9f9',}}>City</th>
            <th style={{ minWidth: '130px',backgroundColor:'#f9f9f9',}}>Status</th>
            <th style={{ minWidth: '130px',backgroundColor:'#f9f9f9',}}>Category</th>
            <th style={{ minWidth: '130px',backgroundColor:'#f9f9f9',}}>Lead <br />Gen. Date</th>
            <th style={{ minWidth: '130px',backgroundColor:'#f9f9f9',}}>Current Company</th>
            <th style={{ minWidth: '130px',backgroundColor:'#f9f9f9',}}>Sales Experience</th>
            <th style={{ minWidth: '130px',backgroundColor:'#f9f9f9',}}>Designation</th>
            <th style={{ minWidth: '130px',backgroundColor:'#f9f9f9',}}>MAPSS <br /> Score</th>
            <th style={{ minWidth: '130px',backgroundColor:'#f9f9f9',}}>Lead Assigned To</th>
            <th style={{ minWidth: '130px',backgroundColor:'#f9f9f9',}}>Tasks</th>
          </tr>
        </thead>
      </table>
    </div>
    <table>
      <tbody>
        {sheetData.map((row, rowIndex) => (
          <tr key={rowIndex}>
            {/* Render fixed columns */}
            <td style={{ minWidth: '130px', position: 'sticky', left: 0, zIndex: 1, backgroundColor:'#f9f9f9' ,backdropFilter: 'blur(5px)',}}>
              {row[0]} {/* Assuming row[0] corresponds to the first fixed column */}
            </td>
            <td style={{ minWidth: '130px', position: 'sticky', left: '130px', zIndex: 1, backgroundColor:'#f9f9f9' ,backdropFilter: 'blur(5px)',}}>
              {row[-2]} {/* Assuming row[-2] corresponds to the second fixed column */}
            </td>
            <td style={{ minWidth: '130px', position: 'sticky', left: '260px', zIndex: 1, backgroundColor:'#f9f9f9' ,backdropFilter: 'blur(5px)',}}>
              {row[4]} {/* Assuming row[4] corresponds to the third fixed column */}
            </td>
            <td style={{ minWidth: '130px', position: 'sticky', left: '390px', zIndex: 1, backgroundColor:'#f9f9f9' ,backdropFilter: 'blur(5px)',}}>
              {row[5]} {/* Assuming row[5] corresponds to the fourth fixed column */}
            </td>
            
            {/* Render remaining columns */}
            {[row[8], row[36], row[37], row[1], row[19], row[18], row[30], row[52], row[43]].map((cell, cellIndex) => (
              <td key={cellIndex} style={{ minWidth: '130px' }}>
                {cell}
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    </table>
  </div>
  
  );
};

export default Interviews;

import React from 'react';
import { Link, Outlet } from 'react-router-dom';
import { FaHome, FaCog, FaUsers, FaUserFriends, FaChartBar } from 'react-icons/fa'; // Import necessary icons
import './SideBar.css';
import Logo from '../Images/rsLogo.png'
const Sidebar = () => {
  return (
    <div className="sidebar">
      {/* Company Logo */}
      <div className="company-logo">
        <img src={Logo} alt="Recruiter Flow" />
      </div>

      {/* Navigation Links */}
      <ul>
        <li>
          <Link to="/admin"> {/* Check this link */}
            <FaHome className="icon" /> {/* Home Icon */}
            Dashboard
          </Link>
        </li>
        <li>
          <Link to="/admin/settings">
            <FaCog className="icon" /> {/* Settings Icon */}
            Settings
          </Link>
        </li>
        <li>
          <Link to="/admin/users">
            <FaUsers className="icon" /> {/* Users Icon */}
            Users
          </Link>
        </li>
        <li>
          <Link to="/admin/leads">
            <FaUserFriends className="icon" /> {/* Manage Users Icon */}
         Leads
          </Link>
        </li>
        <li>
          <Link to="/admin/reports">
            <FaChartBar className="icon" /> {/* Reports Icon */}
            Reports
          </Link>
        </li>
        {/* Add more list items for additional links */}
      </ul>
    </div>
  );
};


export default Sidebar;

import React, { useState, useEffect } from 'react';
import axios from 'axios';
import 'bootstrap/dist/css/bootstrap.min.css';
import { Modal, Button, Form, DropdownButton, Dropdown } from 'react-bootstrap';

const UserList = () => {
  const [users, setUsers] = useState([]);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showUpdateModal, setShowUpdateModal] = useState(false);
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [userIdToDelete, setUserIdToDelete] = useState(null);
  const [userIdToUpdate, setUserIdToUpdate] = useState(null);
  const [username, setUsername] = useState('');
  const [role, setRole] = useState('');
  const [password, setPassword] = useState('');

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const response = await axios.get('https://apikey.intervuer.com/admin'); // Replace '/admin' with your actual backend API endpoint
        setUsers(response.data);
      } catch (error) {
        console.error('Error fetching users:', error);
      }
    };

    fetchUsers();
  }, []); // Empty dependency array ensures the effect runs only once

  const handleUpdateUser = async () => {
    if (userIdToUpdate) {
      try {
        await axios.put(`https://apikey.intervuer.com/admin/${userIdToUpdate}`, { username, role, password }); // Replace '/admin/${userIdToUpdate}' with your actual backend API endpoint for updating a user
        // Update the users list after updating the user
        const updatedUsers = users.map(user =>
          user._id === userIdToUpdate ? { ...user, username, role } : user
        );
        setUsers(updatedUsers);
        console.log('User updated successfully!');
      } catch (error) {
        console.error('Error updating user:', error);
      }
    }
    setShowUpdateModal(false);
  };

  const handleDeleteUser = async () => {
    if (userIdToDelete) {
      try {
        await axios.delete(`https://apikey.intervuer.com/admin/${userIdToDelete}`); // Replace '/admin/${userIdToDelete}' with your actual backend API endpoint for deleting a user
        // Update the users list after deleting the user
        setUsers(users.filter(user => user._id !== userIdToDelete));
        console.log('User deleted successfully!');
      } catch (error) {
        console.error('Error deleting user:', error);
      }
    }
    setShowDeleteModal(false);
  };

  const handleCreateUser = async () => {
    try {
      await axios.post('https://apikey.intervuer.com/admin/create', { username, role, password }); // Replace '/admin' with your actual backend API endpoint for creating a user
      // Refetch the users list after creating the user
      const response = await axios.get('/admin'); // Replace '/admin' with your actual backend API endpoint
      setUsers(response.data);
   alert('User created successfully!');
    } catch (error) {
      console.error('Error creating user:', error);
    }
    setShowCreateModal(false);
  };

  const handleCloseDeleteModal = () => {
    setShowDeleteModal(false);
  };

  const handleCloseUpdateModal = () => {
    setShowUpdateModal(false);
  };

  const handleCloseCreateModal = () => {
    setShowCreateModal(false);
  };

  const handleShowDeleteModal = (userId) => {
    setUserIdToDelete(userId);
    setShowDeleteModal(true);
  };

  const handleShowUpdateModal = (userId) => {
    const userToUpdate = users.find(user => user._id === userId);
    if (userToUpdate) {
      setUserIdToUpdate(userId);
      setUsername(userToUpdate.username);
      setRole(userToUpdate.role);
      setShowUpdateModal(true);
    }
  };

  const handleShowCreateModal = () => {
    setUsername('');
    setRole('');
    setPassword('');
    setShowCreateModal(true);
  };

  return (
    <div className="container">
      <h1 className="text-center mt-5 mb-4">User List</h1>
      <Button variant="dark" style={{position:'absolute',right:'100px', marginBottom:'100px'}} onClick={handleShowCreateModal}>
        Create New User
      </Button>
      <table className="table table-striped mt-5">
        <thead className="thead-dark">
          <tr>
            <th>Username</th>
            <th>Role</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {users.map(user => (
            <tr key={user._id}>
              <td>{user.username}</td>
              <td>{user.role}</td>
              <td>
                <button className="btn btn-primary mr-2" onClick={() => handleShowUpdateModal(user._id)}>Update</button>
                <button className="btn btn-danger" onClick={() => handleShowDeleteModal(user._id)}>Delete</button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      {/* Delete User Modal */}
      <Modal show={showDeleteModal} onHide={handleCloseDeleteModal}>
        <Modal.Header closeButton>
          <Modal.Title>Confirm Deletion</Modal.Title>
        </Modal.Header>
        <Modal.Body>Are you sure you want to delete this user?</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseDeleteModal}>
            Cancel
          </Button>
          <Button variant="danger" onClick={handleDeleteUser}>
            Delete
          </Button>
        </Modal.Footer>
      </Modal>
      {/* Update User Modal */}
      <Modal show={showUpdateModal} onHide={handleCloseUpdateModal}>
        <Modal.Header closeButton>
          <Modal.Title>Update User</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group controlId="formUsername">
            <Form.Label>Username:</Form.Label>
            <Form.Control type="text" value={username} onChange={(e) => setUsername(e.target.value)} />
          </Form.Group>
          <Form.Group controlId="formRole">
            <Form.Label>Role:</Form.Label>
            <DropdownButton
              id="dropdown-basic-button"
              title={role || 'Select Role'}
              variant="primary"
              onSelect={(eventKey) => setRole(eventKey)}
            >
              <Dropdown.Item eventKey="Admin">Admin</Dropdown.Item>
              <Dropdown.Item eventKey="Talent Acquisition Specialist">Talent Acquisition Specialist</Dropdown.Item>
              <Dropdown.Item eventKey="Candidate Evaluation Expert">Candidate Evaluation Expert</Dropdown.Item>
            </DropdownButton>
          </Form.Group>
          <Form.Group controlId="formPassword">
            <Form.Label>Password:</Form.Label>
            <Form.Control type="password" value={password} onChange={(e) => setPassword(e.target.value)} />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseUpdateModal}>
            Cancel
          </Button>
          <Button variant="primary" onClick={handleUpdateUser}>
            Update
          </Button>
        </Modal.Footer>
      </Modal>
      {/* Create User Modal */}
      <Modal show={showCreateModal} onHide={handleCloseCreateModal}>
        <Modal.Header closeButton>
          <Modal.Title>Create User</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group controlId="formNewUsername">
            <Form.Label>Username:</Form.Label>
            <Form.Control type="text" value={username} onChange={(e) => setUsername(e.target.value)} />
          </Form.Group>
          <Form.Group controlId="formNewRole">
            <Form.Label>Role:</Form.Label>
            <DropdownButton
              id="dropdown-basic-button"
              title={role || 'Select Role'}
              variant="primary"
              onSelect={(eventKey) => setRole(eventKey)}
            >
              <Dropdown.Item eventKey="Admin">Admin</Dropdown.Item>
              <Dropdown.Item eventKey="Talent Acquisition Specialist">Talent Acquisition Specialist</Dropdown.Item>
              <Dropdown.Item eventKey="Candidate Evaluation Expert">Candidate Evaluation Expert</Dropdown.Item>
            </DropdownButton>
          </Form.Group>
          <Form.Group controlId="formNewPassword">
            <Form.Label>Password:</Form.Label>
            <Form.Control type="password" value={password} onChange={(e) => setPassword(e.target.value)} />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleCloseCreateModal}>
            Cancel
          </Button>
          <Button variant="primary" onClick={handleCreateUser}>
            Create
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default UserList;

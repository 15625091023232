import React from 'react';
import { Link } from 'react-router-dom';
import Logo from '../Images/rsLogo.png'
import { FaHome, FaCalendarAlt, FaClipboardList, FaListUl, FaSearch, FaBell } from 'react-icons/fa'; // Import necessary icons
import './Talent.css';
import Shortlisted  from '../TalentAq/Shortlisted'

const Sidebar = ({color}) => {
  return (
    <div className="sidebar" >
      {/* Company Logo */}
      <div className="company-logo">
        <img src={Logo} alt="Recruite Force" />
      </div>

      {/* Navigation Links */}
      <ul>
        <li>
          <Link to="/talent">
            <FaHome className="icon" /> {/* Home Icon */}
            Home
          </Link>
        </li>
        <li>
          <Link to="/talent/summary">
            <FaClipboardList className="icon" /> {/* Summary Icon */}
            Summary
          </Link>
        </li>
        <li>
          <Link to="/talent/search">
            <FaSearch className="icon" /> {/* Search Icon */}
            Search
          </Link>
        </li>
        <li>
          <Link to="/talent/shortlisted">
            <FaListUl className="icon" /> {/* Shortlisted Icon */}
         Shortlisted
          </Link>
        </li>
        <li>
          <Link to="/talent/interviews">
            <FaCalendarAlt className="icon" /> {/* Interviews Icon */}
            Interviews
          </Link>
        </li>
        <li>
          <Link to="/talent/notifications">
            <FaBell className="icon" /> {/* Notifications Icon */}
            Notifications
          </Link>
        </li>
        {/* Add more list items for additional links */}
      </ul>
    </div>
  );
};

export default Sidebar;
